<template>
    <div class="main">
        <div class="fyy-source">
            <div class="fyy-index-title">
                <h5>筛选过滤</h5>
            </div>
        <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline" :rules="ruleValidate">
            <el-form-item label="商品类型" prop="goodTypeId">
                <el-select v-model="queryModel.goodTypeId" placeholder="请选择" size="mini">
                    <el-option
                        v-for="item in goodTypeList"
                        :key="item.id"
                        :label="item.goodsName"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="订单状态" prop="payStatus">
                <el-select v-model="queryModel.payStatus" placeholder="请选择" size="mini">
                    <el-option
                        v-for="item in statusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属学校" prop="schoolId">
                <el-select v-model="queryModel.schoolId" placeholder="请选择" size="mini">
                    <el-option
                        v-for="item in schoolList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="创建时间" prop="createTimeRanges">
                <el-date-picker
                size="mini"
                v-model="queryModel.createTimeRanges"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                ></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="handleSearch"
                :loading="loading"
                >查询</el-button>&nbsp;
                <el-button
                    type="info"
                    size="mini"
                    style="margin-left: 8px"
                    @click="handleReset('queryForm')"
                >重置</el-button>
            </el-form-item>            
        </el-form>
        </div>
        <el-divider></el-divider>
        <div class="fyy-index-center">
            <div class="el-col el-col-12">
            <div class="fyy-source">
                <div class="fyy-index-title">
                <h5>营收简报</h5>
                </div>
                <el-row :gutter="20">
                <el-col :span="12" class="center-txt">
                    <div class="grid-content bg-purple">
                    <p>订单笔数</p>
                    <h1>{{revenueNum}}笔</h1>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                    <p>订单金额</p>
                    <h1>{{revenueTotal}}元</h1>
                    </div>
                </el-col>
                </el-row>
            </div>
            </div>
            <div class="el-col el-col-12">
            <div class="fyy-source">
                <div class="fyy-index-title">
                <h5>商品销售统计</h5>
                </div>
                <el-row :gutter="20">
                    <div class="grid-content bg-purple" id="goodsSalesStatisticsDiv" v-loading="pieLoading" element-loading-text="正在加载中"> 
                        
                    </div>
                </el-row>
            </div>
            </div>
        </div>
        <div class="fyy-source fyy-index-bot">
            <div class="fyy-index-title">
            <div class="fyy-index-tab">
                <h5>营收趋势</h5>
                <div class="home-tab">
                    <el-button id="home-tab-left" class="home-tab-left" :class="[isActive?'active':'']" @click="changeIt">订单金额</el-button>
                    <el-button id="home-tab-right" class="home-tab-right" :class="[isActive?'':'active']" @click="changeIt">订单量</el-button>
                </div>
            </div>
            </div>
            <el-row :gutter="20">
                <div class="grid-content bg-purple" id="revenueTrendDiv" style="width:100%;height: 380px;"
                v-loading="pictLoading" element-loading-text="正在加载中">

                </div>
            </el-row>
        <!--
        替换img部分
            <el-row :gutter="20">
            <div class="grid-content bg-purple"> <img src="img/index05.png"/> </div>
            </el-row>
        -->
        </div>
    </div>
</template>
<script>
import orderHomeApi from '@/api/base/orderHome'
import detentionWarningApi from '@/api/bus/detentionWarning'
import goodsInfoApi from "@/api/base/goodsInfo";

export default {
    name:"baseOrderHome",
    data(){
        return{
            queryModel:{
                goodTypeId:"",
                payStatus:"",
                schoolId:"",
                createTimeRanges: "",
            },
            ruleValidate: {
                createTimeRanges: [
                    { required: true, message: "创建时间范围不能为空", trigger: "blur" }
                ],
            },
            schoolList:[],
            goodTypeList:[],
            statusList:[{
                label:"未支付",
                value:"10"
            },{
                label:"已付款",
                value:"20"
            },{
                label:"已退款",
                value:"40"
            },{
                label:"已关闭",
                value:"30"
            },],
            loading: false,
            pictLoading:false,
            pieLoading:false,
            loadingText: '加载中',
            total:"",
            withdrawalTotal:"",
            soonThawTotal:"",
            thawTotal:"",
            revenueNum:"",
            revenueTotal:"",
            pieData:[],
            dayList:[],
            dataList:[],
            isActive: true,
            showModal: false,
            modalTitle: "",
            totalNum:"",
            totalFee:"",
        }
    },
    methods: {
        everyDayMeasuringView() {
            let myChart = this.$echarts.init(
                document.getElementById("goodsSalesStatisticsDiv")
            );

                  // 绘制图表
            myChart.setOption({
                title: {
                    textAlign: 'center'
                },
                 tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                series: [{
                    name: '商品类别',
                    type: 'pie',
                    radius: '80%',
                    center: ['50%', '60%'],
                    data: this.pieData,
                    animation: false,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }]
            });
        },

        revenueTrendView() {
            let myChart = this.$echarts.init(
                document.getElementById("revenueTrendDiv")
            );

            // 绘制图表
            myChart.setOption({
                title: {
                    left: 'center',
                    text: '订单金额：￥'+this.totalFee+'，笔数：'+this.totalNum,
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLabel: {
                        interval: 0,
                        rotate: 40
                    },
                    data: this.dayList
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: this.dataList,
                    type: 'line'
                }]
            });
        },
        loadRevenueTrendView(formData){
            this.pictLoading=true;
            orderHomeApi.revenueTrend(formData).then((resp)=>{
                var jsonData = resp.data;
                
                if (jsonData.result) {
                    this.dayList = jsonData.data.day;
                    this.dataList = jsonData.data.data;
                    this.totalNum = jsonData.data.totalNum;
                    this.totalFee = jsonData.data.totalFee;
                    this.revenueTrendView();
                    this.pictLoading = false;
                }
            });
        },
        handleSearch(){
            this.pieLoading = true;

            var formData = new FormData();

            formData.append("goodsType", this.queryModel.goodTypeId);
            formData.append("payStatus", this.queryModel.payStatus);
            formData.append("schoolId", this.queryModel.schoolId);

            if(this.queryModel.createTimeRanges==null||this.queryModel.createTimeRanges==""){
                var myDate = new Date();

                var start = myDate.getFullYear() + "-" + (myDate.getMonth()) + "-" + myDate.getDate();
                var end = myDate.getFullYear() + "-" + (myDate.getMonth()+1) + "-" + myDate.getDate();

                var createTimeRanges=[];
                createTimeRanges.push(start);
                createTimeRanges.push(end);

                this.queryModel.createTimeRanges = createTimeRanges;
            }

            formData.append("revenueBriefingRange", this.queryModel.createTimeRanges.toString());
            

            orderHomeApi.revenueBriefing(formData).then((resp)=>{
                var jsonData = resp.data;
                
                if (jsonData.result) {
                    this.revenueNum = jsonData.data.revenueNum;
                    this.revenueTotal=jsonData.data.revenueTotal;
                }
            });

            orderHomeApi.goodsSalesStatistics(formData).then((resp)=>{
                var jsonData = resp.data;
                
                if (jsonData.result) {
                    this.pieData = jsonData.data;
                    this.everyDayMeasuringView();
                    this.pieLoading = false;
                }
            });

            formData.append("type", "1");
            this.loadRevenueTrendView(formData);
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        changeIt: function() {
            this.isActive = !this.isActive;

            var formData = new FormData();

            formData.append("goodsType", this.queryModel.goodTypeId);
            formData.append("payStatus", this.queryModel.payStatus);
            formData.append("schoolId", this.queryModel.schoolId);

            if(this.queryModel.createTimeRanges==null||this.queryModel.createTimeRanges==""){
                var myDate = new Date();

                var start = myDate.getFullYear() + "-" + (myDate.getMonth()) + "-" + myDate.getDate();
                var end = myDate.getFullYear() + "-" + (myDate.getMonth()+1) + "-" + myDate.getDate();

                var createTimeRanges=[];
                createTimeRanges.push(start);
                createTimeRanges.push(end);

                this.queryModel.createTimeRanges = createTimeRanges;
            }

            formData.append("revenueBriefingRange", this.queryModel.createTimeRanges.toString());

            if(this.isActive == true){
                formData.append("type", "1");
                this.loadRevenueTrendView(formData);
            }
            else{
                formData.append("type", "2");
                this.loadRevenueTrendView(formData);
            }
        },
            
    },
    created() {
        var self = this;

        detentionWarningApi.schoolList().then(response => {
            var jsonData = response.data;
            this.schoolList = jsonData.data;
        });

        goodsInfoApi.goodsTypeList().then(response => {
            var jsonData = response.data;
            this.goodTypeList = jsonData.data;
        });

    },
    async mounted() {
        console.log("mounted");

        var self = this;

        this.loading = true;
        this.loadingText = "加载中";
        this.pieLoading = true;


        var myDate = new Date();

        var start = myDate.getFullYear() + "-" + (myDate.getMonth()) + "-" + myDate.getDate();
        var end = myDate.getFullYear() + "-" + (myDate.getMonth()+1) + "-" + myDate.getDate();

        var createTimeRanges=[];
        createTimeRanges.push(start);
        createTimeRanges.push(end);

        this.queryModel.createTimeRanges = createTimeRanges;

        var formData = new FormData();
        this.queryModel.payStatus = "20";
        formData.append("payStatus", this.queryModel.payStatus);

        formData.append("revenueBriefingRange", createTimeRanges.toString());

        orderHomeApi.revenueBriefing(formData).then((resp)=>{
            var jsonData = resp.data;
            
            if (jsonData.result) {
               this.revenueNum = jsonData.data.revenueNum;
               this.revenueTotal=jsonData.data.revenueTotal;
            }
        });

        orderHomeApi.goodsSalesStatistics(formData).then((resp)=>{
            var jsonData = resp.data;
            
            if (jsonData.result) {
               this.pieData = jsonData.data;
               this.everyDayMeasuringView();
                this.pieLoading = false;
            }
        });

        formData.append("type", "1");
        this.loadRevenueTrendView(formData);

         this.loading = false;
        
    },
}
</script>
<style lang="scss" scoped src="../../assets/merchantClient/css/admin.scss"></style>
<style>
.el-divider {
  margin: 5px 0;
}

.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}
</style>