import request from '@/utils/request'
import constant from '@/constant'


function pageList(formData) {
    return request.post(constant.serverUrl + "/base/order/home", formData);
}

function revenueBriefing(formData){
    return request.post(constant.serverUrl + "/base/orderHome/revenueBriefing",formData);
}

function goodsSalesStatistics(formData){
    return request.post(constant.serverUrl + "/base/orderHome/goodsSalesStatistics",formData);
}

function revenueTrend(formData){
    return request.post(constant.serverUrl + "/base/orderHome/revenueTrend",formData);
}

function orderInformation(){
    return request.post(constant.serverUrl + "/base/orderHome/orderInformation");
}


export default {
    pageList,revenueBriefing,goodsSalesStatistics,revenueTrend,orderInformation
}